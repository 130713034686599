"use client";
import { AppContext } from "@/context/AppContext";
import useSocket, { SocketOptionsType } from "@/customhooks/useSocket";
import { Config } from "@/utils/config";
import { useContext } from "react";
import { TrtcHandlerPropType } from "../TrtcHandler";
import { Socket } from "socket.io-client";
import dynamic from "next/dynamic";
import PaidDmEventInitializer from "../PaidDmEventInitializer";

const TrtcHandler = dynamic(() => import("../TrtcHandler"), {
  ssr: false,
}) as React.FC<TrtcHandlerPropType>;

const CustomInitializer = () => {
  const context = useContext(AppContext);
  const { token, userId } = context?.auth ?? {};
  const { setPrivateCallSocket, setPaidDmSocket } = context ?? {};
  const socketOptions: SocketOptionsType = {
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 1000,
    reconnectionAttempts: 100,
    transports: ["websocket", "pooling"],
  };
  const handleSetPrivateCallSocket = (socket: Socket) => {
    setPrivateCallSocket && setPrivateCallSocket(socket);
  };

  const handleSetPaidDmSocket = (socket: Socket) => {
    setPaidDmSocket && setPaidDmSocket(socket);
  };
  const SOCKET_URL_PRIVATE_CALL = Config.PRIVATE_CALL_URL;
  const SOCKET_URL_PAID_DM = Config.PAID_DM_URL;
  useSocket({
    SOCKET_URL: SOCKET_URL_PRIVATE_CALL,
    SOCKET_OPTIONS: socketOptions,
    TOKEN: token ?? "",
    SET_SOCKET_CONTEXT: handleSetPrivateCallSocket,
  });

  useSocket({
    SOCKET_URL: SOCKET_URL_PAID_DM,
    SOCKET_OPTIONS: socketOptions,
    TOKEN: token ?? "",
    SET_SOCKET_CONTEXT: handleSetPaidDmSocket,
  });
  return (
    <>
      <TrtcHandler token={token} />
      <PaidDmEventInitializer token={token} />
    </>
  );
};

export default CustomInitializer;
