/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import styles from "./AddDiamondCard.module.scss";
import useTimer from "@/customhooks/usetimer";
import Image from "next/image";
import { Feed_CALL_BUTTON_ICONS } from "@/utils/imageRelativePaths";

const AddDiamondCard = ({ onClose }: { onClose: () => void }) => {
  interface stateType {
    minutes: number;
    seconds: number;
  }
  const [time, setTime] = useState<stateType>({
    minutes: 0,
    seconds: 0,
  });
  const { minutes, seconds } = useTimer(1, 1);

  useEffect(() => {
    setTime({ minutes, seconds });
  }, [seconds]);

  return (
    <div className={styles.addDiamondCardWrapper}>
      <div className={styles.leftSection}>
        <span>
          You have a low balance balance. <br />
          Add now to extend your call.
        </span>

        <button>Recharg Now</button>
      </div>

      <div className={styles.rightSection}>
        <span>Call ends in</span>
        <div className={styles.timer}>
          {time.minutes}:{time.seconds}
        </div>
      </div>
      <div className={styles.closeIcon} onClick={onClose}>
        <Image
          src={Feed_CALL_BUTTON_ICONS.CLOSE_ICON_SMALL}
          alt="closeIcon"
          width={8}
          height={8}
        />
      </div>
    </div>
  );
};

export default AddDiamondCard;
