"use client";
import {
  AppContext,
  ChatContextData,
  ChatMsgContextData,
  rawType,
} from "@/context/AppContext";
import { useContext, useEffect } from "react";
import { paidDMMsgType, ToastType } from "@/utils/common.types";
import { sendChatmessage } from "@/services/chat.service";
import { getMessageFormat } from "@/utils/helper";

export interface PaidDmEventInitializerPropType {
  token?: string;
}

const PaidDmEventInitializer = ({
  token = "",
}: PaidDmEventInitializerPropType) => {
  const {
    paidDmSocket,
    setChatData,
    chatData,
    setChatMsgData,
    setToastData,
    setLoader,
    chatIntakeData,
  } = useContext(AppContext) ?? {};

  useEffect(() => {
    if (!paidDmSocket) return;

    const socket = paidDmSocket;

    const handleChatInviteReceived = (res: unknown) => {
      console.log("Chat Invite Received:", res);
    };

    const handleChatInviteCanceled = (res: unknown) => {
      console.log("Chat Invite Canceled:", res);
      setChatData?.({ isChatPopup: false });
    };

    const handleChatInviteDeclined = (res: unknown) => {
      console.log("Chat Invite Declined:", res);
      setChatData?.({ isChatPopup: false });
      setToastData &&
        setToastData({
          message: "RECIVER IS BUSY",
          type: ToastType.WARNING,
          visible: true,
        });
    };

    const handleChatInviteNoAnswer = (res: unknown) => {
      console.log("Chat Invite No Answer:", res);
      setChatData?.({ isChatPopup: false });
      setToastData &&
        setToastData({
          message: "No Answer",
          type: ToastType.WARNING,
          visible: true,
        });
    };

    const handleChatStarted = (res: ChatContextData) => {
      console.log("Chat Started:", res);
      setLoader && setLoader(false);
      if (res.session?.roomId) {
        setChatData?.({ ...res, isChatPopup: false, onChat: true });
        if (chatIntakeData && chatData?.session?.roomId) {
          const message = getMessageFormat({
            raw: { info: { ...chatIntakeData } } as rawType,
            roomId: chatData?.session?.roomId,
            type: paidDMMsgType.ONBOARDING,
          });

          void sendChatmessage(paidDmSocket, { ...message }, setChatMsgData);
          // setChatMsgData && setChatMsgData(welcomeMessage);
        }
      }
    };

    const handleChatMessageReceived = (res: ChatMsgContextData) => {
      // const messageBody = res.message?.data.body;
      const systemType = res.message?.data.systemType;

      if (
        systemType === "CHAT_ENDED" ||
        systemType === "CHAT_STARTED" ||
        systemType === "CHAT_STARTED"
      ) {
        return;
      }

      setChatMsgData?.(res);
      console.log("Chat Message Received:", res);
    };

    const handleDiamondsCharged = (res: unknown) => {
      console.log("Diamonds Charged:", res);
    };

    const handleLowDiamonds = (res: unknown) => {
      console.log("Low Diamonds Warning:", res);
      setChatData?.({
        ...chatData,
        lowBalancePopup: true,
      });
    };

    const handleChatReconnected = (res: unknown) => {
      console.log("Chat Reconnected:", res);
    };
    const handlebeChatMessageAttributesChanged = (res: unknown) => {
      console.log("Chat Reconnected:", res);
    };

    const handleMemberAttributesChanged = (res: {
      isTyping: boolean;
      isOnline: boolean;
    }) => {
      console.log("Member Attributes Changed:", res);
      if (chatData?.receiver?.user) {
        setChatData?.({
          ...chatData,
          receiver: {
            ...chatData.receiver,
            attributes: res,
          },
        });
      }
    };

    const handleChatEnded = (res: { reason: string; duration: number }) => {
      console.log("Chat Ended:", res);
      if (chatData?.session?.roomId) {
        setChatData?.({
          ...chatData,
          duration: res.duration,
          isReviewPopup: true,
          onChat: false,
        });
        setChatMsgData && setChatMsgData({});
      }

      if (res.reason === "LowBalance") {
        setToastData &&
          setToastData({
            message: res.reason,
            type: ToastType.WARNING,
            visible: true,
          });
      }
      setLoader && setLoader(false);
    };

    // Register socket events
    socket.on("be_chat_invite_received", handleChatInviteReceived);
    socket.on("be_chat_invite_canceled", handleChatInviteCanceled);
    socket.on("be_chat_invite_declined", handleChatInviteDeclined);
    socket.on("be_chat_invite_no_answer", handleChatInviteNoAnswer);
    socket.on("be_chat_started", handleChatStarted);
    socket.on("be_chat_message_received", handleChatMessageReceived);
    socket.on("be_chat_diamonds_charged", handleDiamondsCharged);
    socket.on("be_chat_low_diamonds", handleLowDiamonds);
    socket.on("be_chat_reconnected", handleChatReconnected);
    socket.on(
      "be_chat_member_attributes_changed",
      handleMemberAttributesChanged
    );
    socket.on(
      "be_chat_message_attributes_changed",
      handlebeChatMessageAttributesChanged
    );
    socket.on("be_chat_ended", handleChatEnded);

    // Clean up listeners on component unmount
    return () => {
      socket.off("be_chat_invite_received", handleChatInviteReceived);
      socket.off("be_chat_invite_canceled", handleChatInviteCanceled);
      socket.off("be_chat_invite_declined", handleChatInviteDeclined);
      socket.off("be_chat_invite_no_answer", handleChatInviteNoAnswer);
      socket.off("be_chat_started", handleChatStarted);
      socket.off("be_chat_message_received", handleChatMessageReceived);
      socket.off("be_chat_diamonds_charged", handleDiamondsCharged);
      socket.off("be_chat_low_diamonds", handleLowDiamonds);
      socket.off("be_chat_reconnected", handleChatReconnected);
      socket.off(
        "be_chat_member_attributes_changed",
        handleMemberAttributesChanged
      );
      socket.off(
        "be_chat_message_attributes_changed",
        handlebeChatMessageAttributesChanged
      );
      socket.off("be_chat_ended", handleChatEnded);
    };
  }, [paidDmSocket, setChatData, chatData, setChatMsgData]);

  return null;
};

export default PaidDmEventInitializer;
