import Image from "next/image";
import styles from "./InsufficientBalancePopup.module.scss";
import {
  CLOSE_ICON_GRAY,
  INSUFFICIENT_BALANCE_ICON,
} from "@/utils/imageRelativePaths";
import { ChingariButton } from "chingari-components";
import Popup from "@/common/components/Popup/Popup";
import { useContext } from "react";
import { AppContext } from "@/context/AppContext";
import { useRouter } from "next/navigation";

interface InsufficientBalancePopupPropType {
  show: boolean;
  onClose: () => void;
}
const InsufficientBalancePopup = ({
  show,
  onClose,
}: InsufficientBalancePopupPropType) => {
  const context = useContext(AppContext);
  const router = useRouter();

  const { setPrivateCallData = () => null } = context ?? {};
  return (
    <Popup show={show} onClose={onClose}>
      <div className={styles.IB_popup_container}>
        <Image
          src={INSUFFICIENT_BALANCE_ICON}
          alt="icon"
          height={108}
          width={172}
        />
        <div className={styles.IB_popup_text}>
          <div className={styles.IB_header}>Insufficient Balance</div>
          <span className={styles.IB_discription}>
            Please add money to make call with your astrologer
          </span>
        </div>
        <ChingariButton
          className={styles.btn_top_up}
          title="Top up Wallet"
          onClick={() => {
            onClose();
            router.push("/store");
          }}
        />
        <Image
          src={CLOSE_ICON_GRAY}
          alt="close"
          height={15}
          width={15}
          className={styles.close_popup}
          onClick={onClose}
        />
      </div>
    </Popup>
  );
};

export default InsufficientBalancePopup;
