"use client";
import { useCallback, useContext, useEffect, useState } from "react";
import Image from "next/image";
// import { DOWNLOAD_POPUP_SOURCE } from "../../../utils/common.types";
import styles from "./ProfileCard.module.scss";
// import { followUsers } from "@/services/profile.service";
// import { AppContext } from "@/context/AppContext";
// import useTimer from "@/customhooks/usetimer";
import { useRouter } from "next/navigation";
// import ImageWithFallback from "@/components/ImageWithFallback";
import {
  DIAMOND_ICON_PLAIN,
  IC_EYE,
  IC_PLUS,
  RUPEE_ORANGE_ICON,
  RUPEE_WHITE_ICON,
} from "@/utils/imageRelativePaths";
import { FOLLOW_BTN_TEXT, FOLLOWING_BTN_TEXT } from "@/utils/strings";
import { AppContext } from "@/context/AppContext";
import { followUsers } from "@/services/profile.service";
import useTimer from "@/customhooks/usetimer";
import ImageWithFallback from "@/common/components/ImageWithFallback";
import { ToastType } from "@/utils/common.types";
// import ImageWithFallback from "../../ImageWithFallback";

interface ProfileCardProps {
  icon: string;
  name: string;
  members?: number;
  className?: string;
  // downloadPopup: (source: DOWNLOAD_POPUP_SOURCE) => void;
  contentUserId?: string;
  contentUserName?: string;
}

const ProfileCard = ({
  icon,
  name,
  members,
  className = "",
  contentUserId,
  contentUserName,
}: ProfileCardProps) => {
  const context = useContext(AppContext);
  const { privateCallData, setPopups, userData, setToastData } = context ?? {};
  const { token, userId } = context?.auth ?? {};
  const [isFollow, setIsFollow] = useState<boolean>(
    !!privateCallData?.caller?.isFollowing
  );
  const [isBtnLoader, setIsBtnLoader] = useState<boolean>(false);
  const router = useRouter();

  //On Follow Click --->>

  const onFollowClick = useCallback(() => {
    if (!userData) {
      setPopups && setPopups({ showLoginPopup: true });
    } else {
      const Ids = {
        userId: userId,
        followUserId: privateCallData?.contentOwner?.userId || contentUserId,
      };
      setIsBtnLoader(true);
      void followUsers(Ids, token)
        .then((data) => {
          if (data?.error) {
            setToastData &&
              setToastData({
                message: data?.error,
                type: ToastType.ERROR,
                visible: true,
              });
          }
          // setIsFollow(data?.data?.followId > 0 ? true : false);

          if (isFollow) {
            setIsFollow(false);
          } else {
            setIsFollow(true);
          }
        })
        .catch((error) => {
          console.log(error);
          setToastData &&
            setToastData({
              message: error,
              type: ToastType.ERROR,
              visible: true,
            });
        })
        .finally(() => {
          setIsBtnLoader(false);
        });
    }
  }, [userData, isFollow]);

  // Timer------>>

  const [secondsElapsed, setSecondsElapsed] = useState<{
    res?: string;
    minutes?: number;
    seconds?: number;
  }>({});
  let res: { res?: string; minutes?: number; seconds?: number } = {};
  if (privateCallData?.onCall) {
    res = useTimer();
  }
  useEffect(() => {
    setSecondsElapsed(res);
  }, [res.res]);

  // Handle Profile Click --->>

  const handleProfileClick = () => {
    contentUserName && void router.push(`/${contentUserName}`);
  };
  return (
    <div className={`${styles.profileCard} ${className}`}>
      <div className={styles.leftSection}>
        <ImageWithFallback
          src={icon}
          alt="profile-pic"
          width={40}
          height={40}
          className={styles.profilePic}
          onClick={handleProfileClick}
        />
        <div>
          <p className={styles.name} onClick={handleProfileClick}>
            {name}
          </p>
          <div className={styles.profile_btn_section}>
            <div
              className={styles.followBtn}
              onClick={() => {
                !isBtnLoader && onFollowClick();
              }}
              // aria-disabled={isBtnLoader}
            >
              {isBtnLoader ? (
                <div className={styles.btn_loader}></div>
              ) : (
                <>
                  {!isFollow && (
                    <Image src={IC_PLUS} alt="plus-icon" width={8} height={8} />
                  )}

                  <p>{isFollow ? FOLLOWING_BTN_TEXT : FOLLOW_BTN_TEXT}</p>
                </>
              )}
            </div>

            {privateCallData?.onCall && (
              <div className={styles.price_section}>
                <Image
                  src={RUPEE_WHITE_ICON}
                  alt="plus-icon"
                  width={10}
                  height={10}
                />
                <p>
                  {privateCallData?.contentOwner?.diamondsPerMinuteInInr}/min
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={styles.rightSectionContainer}>
        {privateCallData?.onCall && (
          <div className={styles.rightSectionTimer}>
            <p className={styles.count}>{secondsElapsed.res}</p>
          </div>
        )}

        {members !== undefined && !privateCallData?.onCall && (
          <div className={styles.rightSectionMember}>
            <Image src={IC_EYE} alt="eye" width={13} height={10} />
            <p className={styles.count}>{members}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileCard;
