import { AppContext } from "@/context/AppContext";
import { useState, useEffect, useContext } from "react";

// interface paramiterType {
//   minute: number;
//   second: number;
// }
const useTimer = (
  minute = 0,
  second = 0,
  restart = false
): {
  res: string;
  hours: number;
  minutes: number;
  seconds: number;
  handleRestart: (minute: number, second: number) => void;
  isChatTimer?: boolean;
} => {
  const [time, setTime] = useState<number>(minute * 60 + second); // Initial time in seconds
  const [secondsElapsed, setSecondsElapsed] = useState(0);
  const [restartHandler, setRestartHandler] = useState<boolean>(false);
  const context = useContext(AppContext);
  const { privateCallData, chatData } = context ?? {};

  useEffect(() => {
    let interval: NodeJS.Timer;
    if (minute === 0 && second === 0) {
      interval = setInterval(() => {
        setSecondsElapsed((prevSeconds) => {
          if (!privateCallData?.onCall) {
            clearInterval(interval);
            return 0;
          }
          return prevSeconds + 1;
        });
      }, 1000);
    } else {
      interval = setInterval(() => {
        setTime((prevTime) => {
          if (prevTime === 0) {
            clearInterval(interval);
            return 0; // Timer finished
          }
          return prevTime - 1;
        });
      }, 1000); // Update time every second
    }

    return () => clearInterval(interval); // Clean up interval on unmount
  }, [restart, privateCallData?.onCall, chatData?.onChat, restartHandler]);

  // restart function ----->>

  const handleRestart = (minute?: number, second?: number) => {
    if (minute !== undefined && second !== undefined) {
      setTime(minute * 60 + second);
    }
    setRestartHandler(!restartHandler);
  };

  if (secondsElapsed !== 0) {
    const res = new Date(secondsElapsed * 1000).toISOString().slice(11, 19);
    return { hours: 0, minutes: 0, seconds: 0, res, handleRestart };
  } else {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time / 60) % 60);
    const seconds = time % 60;

    return { res: "", hours, minutes, seconds, handleRestart };
  }

  // return {};
};

export default useTimer;
