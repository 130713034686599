import { useEffect } from "react";

const useWarnOnExit = (callback: () => void) => {
  useEffect(() => {
    const onBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      return (e.returnValue = "");
    };

    const handlePopState = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      callback();
    };
    window.addEventListener("beforeunload", onBeforeUnload);
    window.addEventListener("unload", callback);
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
      // window.removeEventListener("popstate", handlePopState);
      window.addEventListener("unload", callback);
    };
  }, []);
};

export default useWarnOnExit;
