"use client";
import axios from "axios";
import useAppContext from "@/customhooks/useAppContext";
import { useEffect, useState } from "react";
import styles from "@/modules/Astro/components/InsufficientBalancePopup/InsufficientBalancePopup.module.scss";
import Image from "next/image";
import {
  CLOSE_ICON_GRAY,
  INSUFFICIENT_BALANCE_ICON,
} from "@/utils/imageRelativePaths";
import { ChingariButton } from "../../../../../packages/chingari-components";
import Popup from "@/common/components/Popup/Popup";
import { useRouter } from "next/navigation";

const PaymentCheckout = ({
  plan,
  topUpAmount,
  getUserDetails,
}: {
  plan?: string;
  topUpAmount?: number;
  getUserDetails: () => void;
}) => {
  const [error, setError] = useState<string | null>(null);
  const context = useAppContext();
  const { setPrivateCallData, privateCallData, setLoader } = context;
  const token = context.auth.token ?? "";
  const router = useRouter();
  const handleCheckout = async () => {
    if (!plan || plan === "" || plan === "test") return;
    const checkoutRes = await axios.post(
      process.env.NEXT_PUBLIC_BACKEND_BASE_URL + "/v1/plans/" + plan + "/order",
      {
        gateway: "RAZOR_PAY",
        topUpAmount: topUpAmount ? topUpAmount : undefined,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          platform: "web",
          "App-id": "app.astrolive",
        },
      }
    );
    await fetch("/api/set-cookie", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ amount: privateCallData?.topUpAmount }),
    });
    const { order, meta } = checkoutRes.data?.data;
    const razorpay = new window.Razorpay({
      ...meta,
      handler: function (response: object) {
        window.postMessage(
          JSON.stringify({
            event: "payment_success",
            data: JSON.stringify(response),
          })
        );
      },
    });
    razorpay.open();
    setLoader(false);

    razorpay.on(
      "payment.failed",
      function (response: { error: { description: string } }) {
        console.log("Payment - Error", response.error);
        router.push("/payment/failure");
      }
    );
    setPrivateCallData({
      purchasePlan: "test",
      topUpAmount: privateCallData.topUpAmount,
    });
  };
  const onPopUpClose = () => {
    setPrivateCallData({ purchasePlan: undefined, topUpAmount: 0 });
  };
  const messageHandler = (event: MessageEvent) => {
    try {
      if (event && event.data && typeof event.data === "string") {
        const eventData = JSON.parse(event.data);
        if (
          eventData.event === "payment_success" &&
          typeof getUserDetails == "function"
        ) {
          getUserDetails();
          window.location.href = "/payment/success";
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    handleCheckout();
  }, [plan]);

  useEffect(() => {
    setLoader(true);
    window.addEventListener("message", messageHandler);

    return () => {
      // onPopUpClose()
      window.removeEventListener("message", messageHandler);
    };
  }, []);
  return (
    <Popup show={!!error} onClose={onPopUpClose}>
      <div className={styles.IB_popup_container}>
        <Image
          src={INSUFFICIENT_BALANCE_ICON}
          alt="icon"
          height={108}
          width={172}
        />
        <div className={styles.IB_popup_text}>
          <div className={styles.IB_header}>Payment Failed</div>
          <span className={styles.IB_discription}>{error}</span>
        </div>
        {!error && (
          <ChingariButton
            className={styles.btn_top_up}
            title={"Top up Wallet"}
            onClick={() => router.push("/store")}
          />
        )}
        <Image
          src={CLOSE_ICON_GRAY}
          alt="close"
          height={15}
          width={15}
          className={styles.close_popup}
          onClick={onPopUpClose}
        />
      </div>
    </Popup>
  );
};

export default PaymentCheckout;
