import React, { useEffect, useContext } from "react";
import styles from "./Toast.module.scss";
import Image from "next/image";
import {
  CLOSE_ICON,
  CLOSE_ICON_GRAY,
  TOAST_ICON,
} from "@/utils/imageRelativePaths";
import { AppContext } from "@/context/AppContext";

const Toast = () => {
  const context = useContext(AppContext);
  const { ToastData = {}, setToastData = () => null } = context ?? {};

  useEffect(() => {
    let setToastTimer: NodeJS.Timeout;
    let removeToastTimer: NodeJS.Timeout;
    if (ToastData?.type && !ToastData?.visible) {
      setToastTimer = setTimeout(() => {
        setToastData((pre) => ({ ...pre, visible: true }));
      }, 200);
    }
    if (ToastData?.type && ToastData?.visible) {
      removeToastTimer = setTimeout(() => {
        setToastData({});
      }, 5000);
    }
    return () => {
      clearTimeout(setToastTimer);
      clearTimeout(removeToastTimer);
    };
  }, [ToastData]);

  const handleClose = () => {
    setToastData({});
  };

  return (
    <div
      className={`${styles.toastContainer} ${
        ToastData?.visible ? styles.showToast : ""
      } ${styles[ToastData?.type as string]}`}
    >
      <div className={styles.toast_content}>
        <Image
          className={styles.statusIcon}
          src={
            TOAST_ICON[ToastData?.type?.toLocaleUpperCase() as string] ??
            "/assets/icons/astro/toast-warning-color.svg"
          }
          alt="icon"
          width={20}
          height={20}
        />
        <span className={styles.message}>{ToastData?.message}</span>
        <Image
          className={styles.close_icon}
          src={CLOSE_ICON_GRAY}
          alt="close"
          height={11}
          width={11}
          onClick={handleClose}
        />
      </div>
    </div>
  );
};

export default Toast;
