import { ChatContextData, ChatMsgContextData } from "@/context/AppContext";
import { CurrencyType } from "@/types/common.types";
import {
  EmitMessageParams,
  ESessionPaymentType,
  paidDMMsgType,
  ToastDataType,
  ToastType,
} from "@/utils/common.types";
import { Config } from "@/utils/config";
import axios, { AxiosResponse } from "axios";
import { Socket } from "socket.io-client";

export const uploadMessagesAttachments = async (
  token: string,
  fileBody: FormData,
  setToastData: (d: ToastDataType) => void
) => {
  try {
    const res = await axios<AxiosResponse<{ urls: string[] }>>({
      method: "post",
      url: `${Config.PAID_DM_URL}/messages/attachments`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: fileBody,
    });
    return res?.data?.data?.urls[0];
  } catch (error) {
    console.log(error);
    setToastData &&
      setToastData({
        message: "Error",
        type: ToastType.ERROR,
        visible: true,
      });
    throw error;
  }
};

export const sendChatInvite = (
  socket: Socket | null | undefined,
  userId: string,
  setChatData: React.Dispatch<React.SetStateAction<ChatContextData>>,
  setToastData: React.Dispatch<React.SetStateAction<ToastDataType>>,
  currency = CurrencyType.DIAMONDS
) => {
  socket?.emit(
    "fe_chat_send_invite",
    {
      receiverUserId: userId,
      inviteSource: "HOMEPAGE_NEARBY",
      currencyType: currency,
      paymentType: ESessionPaymentType.FREE,
    },
    (response: {
      data: { roomId: string };
      message: string;
      error: { message: string; type: string };
    }) => {
      if (response?.message === "success") {
        setChatData((pre: ChatContextData) => {
          return {
            ...pre,
            isChatPopup: true,
            session: { roomId: response.data.roomId },
          };
        });
      }
      if (response?.error?.type === "INSUFFICIENT_BALANCE") {
        setChatData((pre: ChatContextData) => {
          return {
            ...pre,
            isInsufficientBalancePopup: true,
          };
        });
        return;
      }

      if (response?.error) {
        setToastData &&
          setToastData({
            message: response?.error?.message,
            type: ToastType.WARNING,
            visible: true,
          });
      }
    }
  );
};

export const cancelChatInvite = (
  socket: Socket | null | undefined,
  roomId: string,
  setChatData: (data: ChatContextData) => void
) => {
  socket?.emit(
    "fe_chat_cancel_invite",
    {
      roomId: roomId,
    },
    (response: { message: string }) => {
      if (response?.message === "success") setChatData({});
    }
  );
};

export const endChat = async (
  socket: Socket | null | undefined,
  roomId: string,
  setChatData: (data: ChatContextData) => void
) => {
  socket?.emit(
    "fe_chat_end",
    {
      roomId: roomId,
    },
    (response: unknown) => {
      // setChatData && setChatData({});
      console.log("----------->> emit Chat ended", response);
    }
  );
};

export const sendChatmessage = (
  socket: Socket | null | undefined,
  { type = paidDMMsgType.DEFAULT, data, roomId }: EmitMessageParams,
  setChatMsgData?: (data: ChatMsgContextData) => void
) => {
  const message = {
    type,
    data,
    roomId,
  };
  socket?.emit(
    "fe_chat_send_message",
    { message: message, roomId },
    (response: { data: ChatMsgContextData; message: string }) => {
      if (response.message === "CHAT_STARTED") {
        return;
      }
      if (response?.message === "success" || response?.data?.roomId) {
        setChatMsgData && setChatMsgData(response.data);
      }
    }
  );
};
